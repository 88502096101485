import React from "react"
import Marquee from "react-fast-marquee"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { useLocation } from "@reach/router"
import styled from "styled-components"
import { IdComponent } from "../Reusable/id-components"
import bgGreyRotate from "../../images/logo-big-grey-rotate.svg"
import { BackgroundImg } from "./HowToUse"

function Partner({ isEnglish }) {
  const data = useStaticQuery(graphql`
    {
      events: allStrapiPartners {
        nodes {
          id
          partnerImage {
            childrenImageSharp {
              fluid(quality: 20, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <IdComponent title="partner" />
      <PartnerDiv>
        <div className="text-center">
          <MainTitle>Partners</MainTitle>
          <MainDesc>
            {isEnglish
              ? "Persatuan Pelajar Indonesia and Education Consultant"
              : "Persatuan Pelajar Indonesia dan Konsultan Pendidikan"}
          </MainDesc>
        </div>
        <PartnerContainer>
          <Marquee speed={150} gradient={false} pauseOnHover={true}>
            {data.events.nodes.map(data => {
              return (
                <PartnerImage
                  fluid={data?.partnerImage?.childrenImageSharp[0]?.fluid}
                  alt="Partner"
                  key={data.id}
                />
              )
            })}
          </Marquee>
          <BackgroundImg src={bgGreyRotate} alt="logo" left="700px" top="-600px" />
        </PartnerContainer>
      </PartnerDiv>
    </>
  )
}

export default Partner

const PartnerDiv = styled.div`
  margin-top: 150px;
  position: relative;
  @media (max-width: 576px) {
    padding: 0px 10px;
  }
`

const MainTitle = styled.h2`
  color: #102c57;
  font-size: 50px;
  font-family: "Typefez-extrabold";
  @media (max-width: 576px) {
    font-size: 38px;
  }
`

const MainDesc = styled.p`
  font-family: "Typefez-reg";
  font-size: 18px;
`

const PartnerContainer = styled(Container)`
  position: relative;
  text-align: center;
  padding: 30px 0px;
  box-shadow: 0px 10px 30px rgba(15, 44, 87, 0.12);
  border-radius: 15px;
  background-color: white;
`

const PartnerImage = styled(Img)`
  width: 100px;
  height: auto;
  margin-left: 2rem;
  margin-right: 2rem;
  filter: grayscale(100%);
  :hover {
    filter: grayscale(0%);
  }
  @media (max-width: 576px) {
    width: 50px;
  }
`
