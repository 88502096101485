import React from "react"
import styled from "styled-components"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import BeeNext from "../../images/BeeNext.jpeg"
import EastVenture from "../../images/EastVentures.jpeg"
import YellowArrow from "../../images/investor-yellow-arrow.png"
import { IdComponent } from "../Reusable/id-components"

function Investor({ isEnglish }) {
  return (
    <>
      <IdComponent title="investor" />
      <InvestorContainer>
        <Row>
          <Col lg={5}>
            <DivTitle>
              {isEnglish ? (
                <InvestorTitle>
                  Supported by <InvestorBr /> world-class <InvestorBr />{" "}
                  investor
                </InvestorTitle>
              ) : (
                <InvestorTitle>
                  Didukung oleh <InvestorBr /> investor <InvestorBr /> kelas
                  dunia
                </InvestorTitle>
              )}
            </DivTitle>
          </Col>
          <Col lg={7} className="d-flex align-items-center justify-content-end">
            <DivImage>
              <InvestorImg
                loading="lazy"
                src={EastVenture}
                alt="logo-east-venture"
              />
              <InvestorImg loading="lazy" src={BeeNext} alt="logo-bee-next" />
            </DivImage>
          </Col>
        </Row>
      </InvestorContainer>
    </>
  )
}

export default Investor

const InvestorContainer = styled(Container)`
  margin-top: 150px;
  background-color: #102c57;
  border-radius: 15px;
  @media (max-width: 576px) {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
`

const DivTitle = styled.div`
  padding: 30px;
  @media (max-width: 992px) {
    text-align: center;
  }
`

const DivImage = styled.div`
  margin-right: -50px;
  height: 150px;
  background-color: white;
  box-shadow: 0px 10px 30px rgba(15, 44, 87, 0.15);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 30px;
  @media (max-width: 992px){
    margin-right: 0px;
    margin-bottom: 20px;
  }
`

const InvestorTitle = styled.h4`
  color: white;
  font-family: "Typefez-black";
  font-size: 42px;
`

const InvestorBr = styled.br`
  @media (max-width: 992px) {
    display: none;
  }
`

const InvestorImg = styled.img`
  margin: 0px 15px;
  width: 250px;
  height: 100px;
  @media (max-width: 992px){
    width: 180px;
    height: 70px;
  }
`
